var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vs-card", [
    _c(
      "div",
      { staticClass: "py-8", attrs: { slot: "header" }, slot: "header" },
      [
        _c("h2", [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]),
        _c("h4", { staticClass: "pt-1" }, [
          _vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")
        ]),
        _vm.descriptionTitle
          ? _c("small", { staticClass: "px-8" }, [
              _vm._v(_vm._s(_vm.descriptionTitle))
            ])
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "px-8 pb-8" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }